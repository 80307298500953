import React from "react";
import {Create, SimpleForm, NumberInput, TextInput, ReferenceInput, SelectInput, useTranslate, useCreateController, useNotify} from "react-admin";
import {PermissionField} from "../PermissionField/PermissionField";
import {validation} from "../../utils/validators";
import {addPermissionLabel} from "../../utils/addPermissionLabel";
import {CurrencyField} from "../fields/CurrencyField/CurrencyField";
import { useHistory } from 'react-router-dom'
import { rawDataProvider } from '../../providers/dataProvider'
import { apiURL } from '../../utils/apiURL'


const ManualPaymentLinkCreate = (props) => {
    const translate = useTranslate()
    const {resource, basePath} = props
    const history = useHistory();
    const notify = useNotify();
  
    const controllerProps = useCreateController({
      ...props,
      basePath,
      resource,
      undoable: false,
    })
  
    controllerProps.save = (data, redirectTo, _a = {}) => {
        const res = rawDataProvider("CUSTOM_POST", `${apiURL}/react_admin/api/create_manual_payment_link`, {
          ...data
        })
        res.then(async (data) => {
          if (data.json.status === 'success') {
            history.push(`/fbp_pricing/manualpaymentlink/${data.json.id}`)
          }
        }).catch(async error => {
          if (error?.message) {
            notify(error?.message, 'error', null, false, 4000)
          }
        })
    }

  return (
    <Create
      title={`${translate('resources.fbp_pricing/manualpaymentlink.create')}`}
      {...props}
    >
      <SimpleForm 
        variant="outlined" 
        save={controllerProps.save}
        initialValues={{data: {}}}>

        <ReferenceInput source="merchant_id" reference="fbp_pricing/merchant" isRequired={true}>
            <SelectInput optionText="iname" />
        </ReferenceInput>

        <ReferenceInput source="endpoint_id" reference="fbp_pricing/product" isRequired={true}>
            <SelectInput optionText="name" />
        </ReferenceInput>

        <NumberInput variant="outlined" source="price" isRequired={true} />

        <TextInput variant="outlined" source="merchant_order" isRequired={true}/>

        <ReferenceInput source="currency_code_id" reference="fbp_common/currency" isRequired={true}>
            <SelectInput optionText="code" />
        </ReferenceInput>

      </SimpleForm>
    </Create>
  );
};

export default ManualPaymentLinkCreate;
