import React, {useState} from "react";
import {
    Edit,
    SimpleForm,
    useTranslate,
    TextInput,
    TextField,
    ReferenceField,
    NumberInput,
    AutocompleteInput,
    DataProviderContext, SelectInput, useLocale
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {BeautifyCurrencyAmountField} from "../../utils/currencyUtils";

const useStyles = makeStyles({
     editor: {
         minWidth: 450,

        '& .MuiInputBase-root': {
            overflow: "hidden",
            resize: "both",
        }
    },
});

const ManualPaymentLinkEdit = (props) => {
    const dataProvider = React.useContext(DataProviderContext);
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();


    return (
        <Edit
            title={translate('resources.fbp_pricing/manualpaymentlink.edit')}
            {...props}
        >
            <SimpleForm variant="outlined"
            >

                <BeautifyCurrencyAmountField source="price" amountField="price" codeField="currency_code"/>

                <TextField source="link" />

                <TextField source="merchant_order" />

                <ReferenceField source="merchant_id" reference="fbp_pricing/merchant" link={true}>
                <TextField source="iname"/>
                </ReferenceField>

                <ReferenceField source="endpoint_id" reference="fbp_pricing/product" link={true}>
                <TextField source="name"/>
                </ReferenceField>
                                
            </SimpleForm>
        </Edit>
    )
}

export default ManualPaymentLinkEdit;