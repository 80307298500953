export default {
  ra: {
    boolean: {
      null: 'Не выбрано',
    },
  },

  modals: {
    delete: {
      'title': 'Вы уверены, что хотите удалить этот объект?',
      'text': 'Это действие не может быть отменено.',
    }
  },

  date_pickers: {
    cancel: 'Отменить',
    ok: 'OK',
    clear: 'Очистить'
  },

  languages: {
    english: 'Английский',
    russian: 'Русский',
  },

  roles: {
    merchant: 'Мерчант',
    admin: 'Админ',
  },

  errors: {
    json: 'Некорректный формат JSON'
  },

  filters: {
    search_by_id: "По ID",
    number_price_range_from: "Сумма от",
    number_price_range_to: "Сумма до",
    number_amount_range_from: "Сумма от",
    number_amount_range_to: "Сумма до",
    number_search_by_price_range: "По сумме",
    number_search_by_amount_range: "По сумме",
    update_date_from: "Дата обновления с",
    update_date_to: "Дата обновления с",
    search_by_update_date: "По дате обновления",
    created_date_from: 'Дата создания с',
    created_date_to: 'Дата создания до',
    search_by_create_date: 'По дате создания',
    search_by_product: 'По эндпоинту',
    search_by_payment_status: 'По статусу платежа',
    search_by_merchant: 'По мерчанту',
    search_by_merchant_order: 'По номеру заказа',
    search_by_merchants: 'По мерчантам',
    search_by_day: 'С',
    search_by_day_to: 'По',
    search_by_status: 'По статусу',
    search_by_http_status: 'По http коду ответа',
    granularity: 'Гранулярность',
  },

  menu: {
    balance: 'Баланс'
  },

  actions: {
    login: 'Вход',
    change_password: 'Сменить пароль',
    send_by_email: 'Отправить на email',
  },

  forms: {
    change_password: {
      password: 'Пароль',
      confirm_password: 'Подтвердите пароль',
      submit: 'Установить новый пароль',
    },
  },

  pages: {
    charts: {
      menu: 'Графики',
      title: 'Графики',
      chart: 'График',
      dimension: 'Срез',
      granularity: 'Granularity',
      invoice_sum_price: 'invoice_sum_price',
      invoice_count: 'invoice_count',
      withdrawal_sum_amount: 'withdrawal_sum_amount',
      withdrawal_count: 'withdrawal_count',
      charts: {
        invoice_sum_price: 'Сумма успешных платежей',
        invoice_count: 'Количеству успешных платежей',
        withdrawal_sum_amount: 'Сумма успешных выплат',
        withdrawal_count: 'Количеству успешных выплат',
      },
      slice: {
        product: 'Эндпоинт',
        merchant: 'Мерчант',
      }
    },
    dashboard: {
      menu: 'Dashboard',
      title: 'Dashboard',
    },
    faq: {
      menu: 'Часто задаваемые вопросы',
      title: 'Часто задаваемые вопросы',
      question1: '',
      answer1: '',
    },
    balances: {
      menu: 'Балансы',
      title: 'Балансы',
      merchant_balance_title: 'Баланс',
      merchant_rolling_reserve_title: 'Роллинг Резерв',
    },
  },

  resources: {
    'fbp_pricing/invoice': {
      name: 'Платеж||||Платежи',
      menu: 'Платежи',
      fields: {
        id: 'ID',
        price: 'Сумма',
        created_at: 'Дата создания',
        fee_from_merchant: 'Комиссия мерчанта',
        customer_fee: 'Комиссия клиента',
        merchant: 'Мерчант',
        primary_payment_gate: 'Платежный шлюз',
        currency_code: 'Код валюты',
        data: 'Дата',
        external_id: 'Внешний ID',
        is_test: 'Тестовый',
        last_payment_error: 'Причина отказа',
        last_refund_error: 'Причина отказа при возврате',
        logs: 'Логи',
        merchant_order: 'Номер заказа',
        payment_status: 'Статус платежа',
        payment_status_updated_at: "Дата обновления",
        primary_product: 'Эндпоинт',
        primary_terminal: 'Терминал',
        refund_status: 'Статус возврата',
        refund_status_updated_at: 'Дата обработки возрата',
        payment_gate: 'Платежный шлюз',
        amount: "Сумма",
        amount_paid: "Зачислено",
      },
      statuses: {
        complete: 'Успешный',
        failed: 'Неуспешный',
        pending: 'В процессе',
        refunded: 'Возвращен',
        new: 'Новый',
        partial_complete: 'Частично оплачен',
      },
      refund_statuses: {
        complete: 'Успешный',
        failed: 'Неуспешный',
        pending: 'В процессе',
        new: 'Новый',
      },
      refund: {
        open: "Вернуть",
        modal: {
          title: "Возврат платежа",
          text: "Подтвердите возврат платежа",
          submit: "Подтвердить",
          cancel: "Отмена",
          notify_requested: "Заявка на возврат отправлена",
        }
      }
    },

    'fbp_pricing/refund': {
      name: 'Возврат||||Возвраты',
      menu: 'Возвраты',
      fields: {
      }
    },

    'fbp_pricing/merchant': {
      name: 'Мерчант||||Мерчанты',
      menu: 'Мерчанты',
      create: 'Создать мерчанта',
      edit: 'Редактировать мерчанта',

      urlTypeInfo: {
        finish_url: "В данном поле вы можете указать адрес в формате https://url.com/finish, на который будет перенаправлен пользователь после совершения оплаты",
        notification_url: "В данном поле вы можете указать адрес в формате https://url.com/notification, на который будет отправлен результат (нотификация) совершения операции",
      },

      fields: {
        id: 'ID',
        urls: "URL",
        iname: 'Наименование',
        name: 'Наименование',
        api_secret: 'API-ключ',
        brand_name: 'Brand name',
        company: 'Company',
        is_test: 'Тест',
        is_available_for_invoice: 'Доступен для платежей',
        is_available_for_withdrawal: 'Доступен для выплат',
        person: 'Person',
        primary_email: 'Адрес электронной почты',
        data: 'Дата',
      },

      additional_settings: 'Дополнительные настройки'
    },

    'fbp_pricing/merchantcommission': {
      name: 'Комиссия||||Комиссии',
      create: 'Добавить комиссию',
      edit: 'Редактировать комиссию',
      fields: {
        id: 'ID',
        commission_object: 'Тип операции',
        commission_type: 'Тип комиссии',
        fix_amount: 'Фиксированная',
        merchant: 'Мерчант',
        min_amount: 'Минимальная',
        percent: 'Процент',
        up_to: 'Up to',
      },
      commission_types: {
        refund: 'Возврат',
        invoice: 'Платеж',
        withdrawal: 'Выплата'
      }
    },

    'fbp_pricing/product': {
      name: 'Эндпоинт||||Эндпоинты',
      connected_products: 'Связанные Эндпоинты',
      empty_connected_products: 'Нет связанных Эндпоинтов',
      menu: 'Эндпоинты',
      create: 'Создать Эндпоинт',
      edit: 'Редактировать Эндпоинт',
      fields: {
        id: 'ID',
        merchant: 'Мерчант',
        name: 'Наименование',
        status: 'Статус',
        data: 'Дата',
        is_available_for_invoice: 'Доступен для платежей',
        is_available_for_withdrawal: 'Доступен для выплат'
      },
      status: {
        new: "Новый"
      }
    },

    'fbp_pricing/productcommission': {
      name: 'Комиссия||||Комиссии',
      create: 'Добавить комиссию',
      edit: 'Редактировать комиссию',
      fields: {
        id: 'ID',
        commission_object: 'Тип операции',
        commission_type: 'Тип комиссии',
        fix_amount: 'Фиксированная',
        merchant: 'Мерчант',
        min_amount: 'Минимальная',
        percent: 'Процент',
        up_to: 'Up to',
      }
    },

   'fbp_pricing/productstatus': {
      name: 'Статус Эндпоинта||||Статус Эндпоинтов',
      fields: {
        id: 'ID',
        description: 'Описание',
        iname: 'Название',
        name: 'Название',
      },
       statuses: {
        new: 'Новый'
      }
    },

    'fbp_pricing/withdrawalrequest': {
      name: 'Выплата||||Выплаты',
      menu: 'Выплаты',
      fields: {
        id: 'ID',
        amount: 'Сумма',
        requested_amount: 'Запрашиваемая сумма',
        fee_from_merchant: 'Комиссия мерчанта',
        status: 'Статус',
        created_at: 'Дата создания',
        currency_code: 'Код валюты',
        data: 'Дата',
        external_id: 'Внешний ID',
        finished_at: 'Дата обработки',
        is_test: 'Тестовый',
        logs: 'Логи',
        merchant: 'Мерчант',
        merchant_order: 'Номер заказа',
        primary_payment_gate: 'Платежный шлюз',
        primary_product: 'Эндпоинт',
      },
      statuses: {
        new: 'Новый',
        accepted: 'Принят',
        pending: 'В процессе',
        done: 'Успешный',
        rejected: 'Отклонен',
        error: 'Ошибка'
      }
    },

    'fbp_pricing/bulkwithdrawal': {
      name: 'Массовые выплаты||||Массовые выплаты',
      menu: 'Массовые выпалаты',
      edit: 'Редактировать массовую выплату',
      create: 'Создать массовую выплату',
      download_registry: 'реестр',
      download_registry_result: 'реестр результатов',
      fields: {
        merchant_id: 'Мерчант',
        endpoint_id: 'Эндпоинт',
        registry: 'Файл',
      }
    },

    'fbp_pricing/bulkwithdrawalitem': {
      name: 'Выплата||||выплаты'
    },    

    'fbp_pricing/manualpaymentlink': {
      name: 'Платежная ссылка||||Платежные ссылки',
      menu: 'Платежные ссылки',
      edit: 'Редактировать платежную ссылку',
      create: 'Создать платежную ссылку',
      fields: {
        created_at: 'Создан',
        link: 'Ссылка',
        merchant_order: 'Номер заказа',
        price: 'Сумма',        
        merchant_id: 'Мерчант',        
        endpoint_id: 'Эндпойнт',  
        currency_code_id: 'Валюта',
      }
    },       

    'sbank/registryfile': {
      name: 'Отчет||||Отчеты',
      menu: 'Отчеты',
      create_form: {
        start: 'Начало периода',
        end: 'Окончание периода',
        create_registry_file: 'Создать отчет',
        set_date_for_registry: 'Создание отчета',
        confirm_create_registry_file: 'Создать отчет',
      },
      download_registry_file: 'Скачать',
      fields: {
        id: 'ID',
        created_at: 'Дата создания',
        day: 'Начало периода',
        day_to: 'Окончание периода',
        merchant: 'Мерчант',
        registry_file_url: 'Отчет',
        registry_type: 'Тип отчета',
      },
       types: {
        payment: 'Платежи',
        withdrawal: 'Выплаты',
      },
      creating_registry_message: 'Отчёт создаётся',
      created_registry_message: 'Отчёт создан',
      error_creating_registry_message: 'Отчёт не создан, повторите попытку',
    },

    'fbp_pricing/paymentgate': {
      fields: {
        iname: "iname",
      },
    },

    'sbank/settlement': {
      name: 'Расчет||||Расчеты',
      menu: 'Расчеты',
      fields: {
        id: 'ID',
        amount: 'Сумма',
        requested_amount: 'Запрашиваемая сумма',
        fee_from_merchant: 'Комиссия мерчанта',
        status: 'Статус',
        created_at: 'Дата создания',
        currency_code: 'Код валюты',
        data: 'Дата',
        external_id: 'Внешний ID',
        finished_at: 'Дата обработки',
        is_test: 'Тестовый',
        logs: 'Логи',
        merchant: 'Мерчант',
        merchant_order: 'Номер заказа',
        primary_payment_gate: 'Платежный шлюз',
        primary_product: 'Эндпоинт',
      },
    },

    'fbp_pricing/terminal': {
      name: 'Терминал||||Терминалы',
      create: 'Создать терминал',
      edit: 'Редактировать терминал',
      menu: 'Терминалы',
      fields: {
        id: "ID",
        'data.portal_id': 'Наименование',
        current_day_amount: 'Текущая сумма за сутки',
        current_day_count: 'Текущее количество за сутки',
        current_month_amount: 'Текущая сумма за месяц',
        current_month_count: 'Текущее количество за месяц',
        limit_card_day_amount: 'Лимит суммы по карте в день',
        limit_card_day_count: 'Лимит кол-ва операций по карте в день',
        limit_card_withdrawal_day_amount: 'Лимит суммы выплат по карте в день',
        limit_card_withdrawal_day_count: 'Лимит кол-ва выплат по карте в день',
        limit_day_amount: 'Лимит по общей сумме в день',
        limit_day_count: 'Лимит по общему количеству в день',
        limit_month_amount: 'Лимит по общей сумме в месяц',
        limit_month_count: 'Лимит по общему количеству в месяц',
        min_amount: 'Минимальная сумма',
        payment_gate: 'Платежный шлюз',
        status: 'Статус',
        terminal_owner: 'Владелец терминала',
        data: 'Data',
      },
       statuses: {
        active: 'Активный',
        disabled: 'Неактивный',
        deleted: 'Удален',
      }
    },

    'fbp_pricing/terminalowner': {
      name: 'Владелец терминала||||Владельцы терминала',
      create: 'Создать владельца терминала',
      edit: 'Редактировать владельца терминала',
      menu: 'Владельцы терминалов',
      fields: {
        id: "ID",
        iname: 'Наименование',
        limit_card_day_count: 'Лимит кол-ва операций по карте в день',
        limit_card_withdrawal_day_count: 'Лимит кол-ва выплат по карте в день',
      },
    },

    'fbp_webhook/webhook': {
      name: 'Вэбхук||||Вэбхуки',
      create: 'Создать вэбхук',
      edit: 'Редактировать вэбхук',
      menu: 'Вэбхуки',
      fields: {
        created_at: 'Дата создания',
        product: 'Эндпоинт',
        merchant: 'Мерчант',
        url: 'Url',
        method: 'Метод',
        retry_count: 'Кол-во попыток',
        status: 'Cтатус',
        webhook_type: 'Тип вэбхука',
        response_http_status: 'Код ответа',
        response_body: 'Тело ответа',
        merchant_order: 'Заказ',
        invoice_id: 'Id платежа',
        withdrawal_request_id: 'Id выплаты',
        next_retry_time: 'Время следующей попытки'
      },
      send_webhooks_action: 'Повторно отослать'
    },

    'sbank/sbankuser': {
      name: 'Пользователь||||Пользователи',
      create: 'Создание пользователя',
      edit: 'Редактировать пользователя',
      menu: 'Пользователи',
      fields: {
        id: "ID",
        username: 'Имя пользователя',
        merchants: 'Мерчанты',
      },
    },

    'sbank/invoicescreenshot': {
      name: 'Чек||||Чеки',
      create: 'Добавить чек',
      edit: 'Редактировать чек',
      menu: 'Чеки',
      download: 'Скачать',
      upload: 'Загрузить чек',
      fields: {
        id: "ID",
        status: "Статус",
        updated_at: "Дата обновления",
        approved_at: "Дата подтверждения",
        rejected_reason: "Причина отмены",
        screenshot_url: "Файл",
      },
    },

  },

  common: {
    yes: 'Да',
    no: 'Нет'
  }
}
