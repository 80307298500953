import lists from "../../components/lists"
import LinkIcon from "@mui/icons-material/Link"
import ManualpaymentlinkCreate from "../../components/create/ManualPaymentLinkCreate"
import ManualpaymentlinkEdit from "../../components/edit/ManualPaymentLinkEdit"


export const manualpaymentlink = {
  name: 'fbp_pricing/manualpaymentlink',
  icon: LinkIcon,
  create: ManualpaymentlinkCreate,
  edit: ManualpaymentlinkEdit,
  list: lists.ManualPaymentLinkList,
}
