import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {Divider, ListItemIcon, MenuItem, useMediaQuery} from '@material-ui/core'
import {useTranslate, MenuItemLink} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import {invoice} from "../resources/fbp_pricing/invoice"
import {withdrawalrequest} from "../resources/fbp_pricing/withdrawalrequest"
import {registryfile} from "../resources/sbank/registryfile"
import {merchant} from "../resources/fbp_pricing/merchant"
import {product} from "../resources/fbp_pricing/product"
import {refund} from "../resources/fbp_pricing/refund"
import {bulkwithdrawal} from "../resources/fbp_pricing/bulkwithdrawal"
import {manualpaymentlink} from "../resources/fbp_pricing/manutalpaymentlink"
import {webhook} from "../resources/fbp_webhook/webhook"
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import {useLogout} from "ra-core"
import Balance from "./components/Balance"
import {useResourcePermissions} from "../utils/useResourcePermissions"
import {settlements} from "../resources/sbank/settlements/settlements"
import {terminal} from "../resources/fbp_pricing/terminal/terminal"
import {terminalowner} from "../resources/fbp_pricing/terminalowner/terminalowner"
import {user} from "../resources/auth/user/user"
import {BalancesPage} from "../pages/BalancesPage"
import {ChartsPage} from '../pages/ChartsPage'

const MenuItems = [
  invoice,
  withdrawalrequest,
  bulkwithdrawal,  
  refund,
  settlements,
  registryfile,
  user,
  merchant,
  product,
  terminal,
  terminalowner,
  webhook,
  manualpaymentlink,
]

const useListStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    width: 260,
    boxSizing: "border-box",
  },
  topInfo: props => ({
    transition: 'padding ease .3s, height ease .3s',
    height: props.open ? `100px` : `32px`,
    padding: props.open ? `16px 24px 12px` : `16px 16px 12px`,
  }),
  menuBlock: props => ({
    transition: 'padding ease .3s',
    padding: props.open ? `12px 8px 0` : `12px 0 0`,
  }),
  menuItem: props => ({
    maxWidth: props.open ? null : '55px',
    marginBottom: 8,
    borderRadius: 6,
    fontSize: 13,
    opacity: .8,
    // color: `rgba(255, 255, 255, 0.7)`,
  }),
  menuIcon: () => ({
    maxWidth: '40px',
    opacity: .8,
    // color: `rgba(255, 255, 255, 0.7)`,
  }),
  divider: props => ({
    transition: 'max-width ease .25s, margin ease .25s',
    margin: props.open ? `0 16px` : '0 12px',
    maxWidth: props.open ? 300 : 30,
  }),
}))

const Menu = ({onMenuClick, dense}) => {
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const {loaded, actions} = useResourcePermissions('features/balance')
  const {loaded: allLoaded, actions: allActions} = useResourcePermissions()
  const classes = useListStyles({open})

  const logout = useLogout()
  const handleClick = useCallback(() => logout(null, null, false), [
    logout,
  ])

  return (
    <div className={classes.root}>

      {loaded && actions.includes('view') && (
        <>
          <div className={classes.topInfo}>
            <Balance/>
          </div>
          <Divider className={classes.divider}/>
        </>
      )}

      <div className={classes.menuBlock}>

        {/* <MenuItemLink
          className={classes.menuItem}
          to={ChartsPage.menuConfig.href}
          primaryText={translate(ChartsPage.menuConfig.menu)}
          leftIcon={<ChartsPage.menuConfig.icon className={classes.menuIcon}/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        /> */}

        {loaded && actions.includes('view') && (
          <>
            <MenuItemLink
              className={classes.menuItem}
              to={BalancesPage.menuConfig.href}
              primaryText={translate(BalancesPage.menuConfig.menu)}
              leftIcon={<BalancesPage.menuConfig.icon className={classes.menuIcon}/>}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </>
        )}

        {allLoaded && MenuItems
          .filter(resource => allActions[resource.name]?.actions?.includes('view'))
          .map(resource => (
            <MenuItemLink
              key={resource.name}
              className={classes.menuItem}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.menu`, {smart_count: 1})}
              leftIcon={<resource.icon className={classes.menuIcon}/>}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          ))}

        {/*<MenuItemLink*/}
        {/*  className={classes.menuItem}*/}
        {/*  to={FAQ.menuConfig.href}*/}
        {/*  primaryText={translate(FAQ.menuConfig.menu)}*/}
        {/*  leftIcon={<FAQ.menuConfig.icon className={classes.menuIcon}/>}*/}
        {/*  onClick={onMenuClick}*/}
        {/*  sidebarIsOpen={open}*/}
        {/*  dense={dense}*/}
        {/*/>*/}

        {isXSmall && (
          <MenuItem
            className={classes.menuItem}
            onClick={handleClick}
            component={isXSmall ? 'span' : 'li'}
          >
            <ListItemIcon className={classes.menuIcon} style={{minWidth: 40, maxWidth: 40}}>
              <ExitIcon className={classes.menuIcon}/>
            </ListItemIcon>
            {translate('ra.auth.logout')}
          </MenuItem>
        )}
      </div>
    </div>
  )
}

export default Menu
