import dataProvider from "./providers/dataProvider"
import authProvider from "./providers/authProvider"
import i18nProvider from "./providers/i18nProvider"
import Layout from "./layout/Layout"
import {routes} from "./routes"
import {Admin, Resource, ListGuesser} from "react-admin"
import {invoice} from "./resources/fbp_pricing/invoice"
import {refund} from "./resources/fbp_pricing/refund"
import {withdrawalrequest} from "./resources/fbp_pricing/withdrawalrequest"
import {bulkwithdrawal} from "./resources/fbp_pricing/bulkwithdrawal"
import {manualpaymentlink} from "./resources/fbp_pricing/manutalpaymentlink"
import {settlements} from "./resources/sbank/settlements/settlements"
import {registryfile} from "./resources/sbank/registryfile"
import {merchant} from "./resources/fbp_pricing/merchant"
import {product} from "./resources/fbp_pricing/product"
import {productcommission} from "./resources/fbp_pricing/productcommission"
import {bulkwithdrawalitem } from "./resources/fbp_pricing/bulkwithdrawalitem"
import {terminal} from "./resources/fbp_pricing/terminal/terminal";
import {webhook} from "./resources/fbp_webhook/webhook"
import MerchantUrlCreate from "./components/create/MerchantUrlCreate"
import MerchantUrlEdit from "./components/edit/MerchantUrlEdit"
import MerchantComissionCreate from "./components/create/MerchantComissionsCreate"
import MerchantComissionEdit from "./components/edit/MerchantComissionEdit"
import React from "react"
import {useSelector} from "react-redux"
import {terminalowner} from "./resources/fbp_pricing/terminalowner/terminalowner"
import { user } from "./resources/auth/user/user"

export function SbankAdmin(props) {
  const {history} = props
  const settings = useSelector(({settings}) => settings)
  const theme = settings.themes[settings.current.main]

  return (
    <Admin
      theme={theme}
      disableTelemetry
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
      customRoutes={routes}
      loginPage={false}
      ready={() => <></>}
    >
      {() => [
        <Resource {...invoice} />,
        <Resource {...withdrawalrequest} />,
        <Resource {...bulkwithdrawal} />,     
        <Resource {...manualpaymentlink} />,     
        <Resource {...refund} />,
        <Resource {...settlements} />,
        <Resource {...registryfile} />,
        <Resource {...merchant} />,
        <Resource {...product} />,
        <Resource {...productcommission} />,
        <Resource {...bulkwithdrawalitem} />,
        <Resource {...webhook} />,
        <Resource
          name="fbp_pricing/merchanturl"
          create={MerchantUrlCreate}
          edit={MerchantUrlEdit}
        />,
        <Resource
          name="fbp_pricing/merchantcommission"
          create={MerchantComissionCreate}
          edit={MerchantComissionEdit}
        />,
        <Resource {...user} />,
        <Resource
          name="auth/group"
        />,
        <Resource
          name="fbp_pricing/paymentstatus"
        />,
        <Resource
          name="fbp_pricing/refundstatus"
        />,
        <Resource
          name="fbp_pricing/paymentgate"
        />,
        <Resource
          name="fbp_pricing/productstatus"
        />,
        <Resource
          name="sbank/usermerchant"
        />,
        <Resource
          name="sbank/invoicescreenshot"
        />,
        <Resource
          name="fbp_pricing/merchanturltype"
        />,
        <Resource
          name="fbp_pricing/commissiontype"
        />,
        <Resource
          name="fbp_pricing/merchantaccount"
        />,
        <Resource
          name="fbp_ledger/account"
        />,
        <Resource
          name="fbp_ledger/accounttype"
        />,
        <Resource
          name="fbp_common/currency"
        />,        
        <Resource
          name='features/balance'
        />,
        <Resource
          name='fbp_pricing/terminal'
          {...terminal}
        />,
        <Resource
          name='fbp_pricing/terminalowner'
          {...terminalowner}
        />,
        <Resource
          name="fbp_pricing/merchantcommission"
          create={MerchantComissionCreate}
          edit={MerchantComissionEdit}
        />,
      ]}
    </Admin>
  )
}
