import PaymentsList from "./PaymentsList";
import ProductsList from "./ProductsList";
import RefundList from "./RefundsList";
import WithdrawalsList from "./WithdrawalsList";
import BulkWithdrawalsList from "./BulkWithdrawalsList";
import RegistriesList from "./RegistriesList";
import MerchantsList from "./MerchantsList";
import PaymentStatusList from "./PaymentStatusList";
import WebhookList from "./WebhookList";
import ManualPaymentLinkList from "./ManualPaymentLinkList";                                     

const lists = {
  PaymentsList,
  ProductsList,
  RefundList,
  WithdrawalsList,
  BulkWithdrawalsList,
  RegistriesList,
  MerchantsList,
  PaymentStatusList,
  WebhookList,
  ManualPaymentLinkList,
};

export default lists;
