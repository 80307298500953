import React from "react"
import {List, Datagrid, TextField, DateField, Filter, useTranslate, ReferenceField} from "react-admin"
import {useResourcePermissions} from "../../utils/useResourcePermissions"
import filterCreators from "../../utils/filterCreators"
import { TablePagination } from "../TablePagination/TablePagination";
import i18nProvider from "../../providers/i18nProvider";
import {BeautifyCurrencyAmountField} from "../../utils/currencyUtils";

const {translate} = i18nProvider


const ManualPaymentLinkFilters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
    {filterCreators.createReferenceInput(
      "fbp_pricing/merchant",
      translate('filters.search_by_merchants'),
      "merchant_id",
      "iname",
      false,
    )}
    {filterCreators.createReferenceInput(
      "fbp_pricing/product",
      translate('filters.search_by_product'),
      "endpoint_id",
      "name",
      false,
    )}
    {/*{filterCreators.createDateInput({source: "created_at", format: dateFormat, parse: dateParse})}*/}
  </Filter>
);

const ManualPaymentLinkList = (props) => {
  const {loading, actions} = useResourcePermissions(props.resource)
  const translate = useTranslate()

  if (loading) {
    return null
  }

  const newProps = {...props}
  newProps.hasCreate = actions.includes('create')

  return (
    <List
      {...newProps}
      empty={false}
      exporter={false}
      filters={<ManualPaymentLinkFilters/>}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      pagination={<TablePagination />}
      perPage={TablePagination.defaultPerPage}
    >
      <Datagrid rowClick="edit">
        {/*<TextField source="id" />*/}
        <DateField source="created_at" showTime="{true}" />

        <TextField source="link" />

        <TextField source="merchant_order"  />

        <BeautifyCurrencyAmountField source="price" amountField="price" codeField="currency_code"/>

        <ReferenceField source="merchant_id" reference="fbp_pricing/merchant" link={true}>
          <TextField source="iname"/>
        </ReferenceField>

        <ReferenceField source="endpoint_id" reference="fbp_pricing/product" link={true}>
          <TextField source="name"/>
        </ReferenceField>


      </Datagrid>
    </List>
  )
}

export default ManualPaymentLinkList
