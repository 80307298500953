import React from "react";
import {useRouteMatch} from "react-router-dom"
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    ReferenceField,
    useRedirect, ReferenceInput, AutocompleteInput, FunctionField,
} from "react-admin";
import StatusField from '../fields/StatusField/StatusField';
import filterCreators from "../../utils/filterCreators";
import Drawer from "@material-ui/core/Drawer";
import {useHistory} from "react-router-dom";
import {PaymentView} from "../view/PaymentView";
import {makeStyles} from "@material-ui/core/styles";
import {DateRangeInput} from "../inputs/DateRangeInput/DateRangeInput";
import {dateFormat, dateParse} from "../../utils/dateHelpers";
import i18nProvider from "../../providers/i18nProvider";
import {BeautifyCurrencyAmountField} from "../../utils/currencyUtils";
import {RangeInput} from "../inputs/RangeInput/RangeInput";
import {useResourcePermissions} from "../../utils/useResourcePermissions";
import {TablePagination} from "../TablePagination/TablePagination";
import {colorMapping} from "../../configs/paymentStatuses";
import {refundStatusColorMapping} from "../../configs/refundStatuses";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  // root: {
  //   width: 900,
  // },
}))

const {translate} = i18nProvider

const PaymentFilters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
    <DateRangeInput
      startText={translate('filters.created_date_from')}
      endText={translate('filters.created_date_to')}
      label={translate('filters.search_by_create_date')}
      source={"created_at"}
      format={dateFormat}
      parse={dateParse}
    />
    {filterCreators.createNumberSearchInput({
      source: "id",
      label: 'filters.search_by_id'
    })}
    {filterCreators.createTextSearchInput({
      source: "merchant_order",
      label: 'filters.search_by_merchant_order'
    })}
    <DateRangeInput
      startText={translate('filters.update_date_from')}
      endText={translate('filters.update_date_to')}
      label={translate('filters.search_by_update_date')}
      source={"payment_status_updated_at"}
      format={dateFormat}
      parse={dateParse}
    />
    <RangeInput
      startText={translate('filters.number_price_range_from')}
      endText={translate('filters.number_price_range_to')}
      label={translate('filters.number_search_by_price_range')}
      source={"price"}
    />
    {filterCreators.createReferenceInput(
      "fbp_pricing/merchant",
      translate('filters.search_by_merchant'),
      "merchant",
      "iname",
      false
    )}
    {filterCreators.createReferenceInput(
      "fbp_pricing/product",
      translate('filters.search_by_product'),
      "primary_product",
      "name",
      false
    )}
    {filterCreators.createReferenceInput(
      "fbp_pricing/paymentstatus",
      translate('filters.search_by_payment_status'),
      "payment_status",
      (record) => record && record.id ? translate(`resources.fbp_pricing/invoice.statuses.${record.name}`) : '',
      false
    )}
    {/*<DateInput label="Search by created date" source={"created_at"} format={dateFormat} parse={dateParse}/>*/}
  </Filter>
);

const PaymentsList = (props) => {
  const {resource} = props
  const redirect = useRedirect()
  const history = useHistory()
  const classes = useStyles()
  const {actions: paymentGateActions} = useResourcePermissions(resource, 'primary_payment_gate')

  const pathname = '/fbp_pricing/invoice'
  const basePath = `${pathname}${props.location.search}`

  const drawerClose = () => redirect(basePath)
  const openPayment = (id) => {
    history.push(`${pathname}/${id}${props.location.search}`)
  }

  const match = useRouteMatch(`${pathname}/:id`)
  const isMatch = !!(match && match.params && match.params.id)

  return (
    <div className={classes.root}>
      <List
        {...props}
        empty={false}
        exporter={false}
        filters={<PaymentFilters/>}
        bulkActionButtons={false}
        sort={{field: 'created_at', order: 'DESC'}}
        pagination={<TablePagination/>}
        perPage={TablePagination.defaultPerPage}
      >
        <Datagrid rowClick={openPayment}>
          <TextField source="id"/>
          {/*<TextField label="Merchant id" source="merchant" />*/}
          <ReferenceField source="merchant" reference="fbp_pricing/merchant" link={false}>
            <TextField source="iname"/>
          </ReferenceField>
          <ReferenceField source="primary_product" reference="fbp_pricing/product" link={false}>
            <TextField source="name"/>
          </ReferenceField>
          <TextField source="merchant_order"/>
          <DateField source="created_at" showTime/>
          <DateField source="payment_status_updated_at" showTime/>

          <ReferenceField source="payment_status" reference="fbp_pricing/paymentstatus" link={false}>
              <StatusField colorMap={colorMapping}  translationKey={`resources.fbp_pricing/invoice.statuses`} source="iname"/>
          </ReferenceField>

          <ReferenceField source="refund_status" reference="fbp_pricing/refundstatus" link={false}>
            <StatusField colorMap={refundStatusColorMapping}  translationKey={`resources.fbp_pricing/invoice.statuses`} source="name"/>
          </ReferenceField>

          <BeautifyCurrencyAmountField source="price" amountField="price" codeField="currency_code"/>

          <BeautifyCurrencyAmountField source="amount_paid" amountField="amount_paid" codeField="currency_code"/>

          {paymentGateActions?.includes('view') && (
            <ReferenceField source="primary_payment_gate" reference="fbp_pricing/paymentgate" link={false}>
              <TextField source="iname"/>
            </ReferenceField>
          )}
        </Datagrid>
      </List>

      {isMatch && (
        <Drawer
          open={isMatch}
          anchor="right"
          onClose={drawerClose}
        >
          {isMatch ? (
            <PaymentView
              basePath={basePath}
              resource={'fbp_pricing/invoice'}
              id={match.params.id}
              onCancel={drawerClose}
            />
          ) : null}
        </Drawer>
      )}
    </div>
  );
};

export default PaymentsList;
